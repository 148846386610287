import { useRouter } from "next/router"
import { useEffect } from "react"

import { ICMPublicVideo } from "data/contentData/interfaces/mongodb/ICMPublicVideo"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import { IPublicVideo } from "data/contentData/interfaces/mongodb/IPublicVideo"
import { UserCategoryType } from "data/internal/interfaces/userCategory"
import { CONTENT_TYPE, getContentType } from "lib/getContentType"

import useUserCategory from "Components/UserCategoryModal/useUserCategory"

/**
 * Send custom variables to GTM
 * @param page page if applicable
 */
export default function useGtmCustomVariables(page?: IPage | undefined) {
    const { userCategory } = useUserCategory()
    const router = useRouter()

    useEffect(() => {
        gtmCustomVariables(page, userCategory)
    }, [page, userCategory, router.pathname])
}

function gtmCustomVariables(
    page: IPage | undefined,
    userCategory: UserCategoryType | undefined
) {
    window.dataLayer.push({
        dimension4: page ? getTemplateId(page) : undefined
    })
    window.dataLayer.push({ dimension7: userCategory })
    window.dataLayer.push({
        dimension8: page ? getCampaignCode(page) : undefined
    })
}

function getCampaignCode(page: IPage) {
    const contentType = getContentType(page.types)
    if (contentType === CONTENT_TYPE.CONTENTMARKETING_ARTICLE)
        return (page as ICMPublicVideo).campaignCode

    return undefined
}

function getTemplateId(page: IPage) {
    let templateId: number | undefined
    const contentType = getContentType(page.types)
    switch (contentType) {
        case CONTENT_TYPE.ARTICLE:
            templateId = (page as IPublicVideo).template?.contentId
            break
        case CONTENT_TYPE.CONTENTMARKETING_ARTICLE:
            templateId = (page as ICMPublicVideo).template?.contentId
            break
        case CONTENT_TYPE.SYSTEM:
            templateId = (page as IPublic).template?.contentId
            break
    }

    return templateId
}
